.global-meeting-frame {
  background-color: black;
}

#meeting-my-video { 
  bottom: 18%;
  right: 4%;
}

#meeting-my-video.meeting-hud-hidden {
  bottom: 4%;
}