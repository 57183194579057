body {
  margin: 0;
  font-family: "SF", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  color:#24272a;
  height: 100%;
}
html, body, #root{
  width:100%;
  height:100vh;
}

@font-face {
  font-family: "SF";
  font-weight:400;
  src: url(./fonts/sfpro.ttf) format('truetype'),
  url(./fonts/sfpro.woff2) format("woff2"),
  url(./fonts/sfpro.woff) format("woff"),
  url(./fonts/sfpro.svg) format("svg");
}

@font-face {
  font-family: "SF";
  font-weight: 500;
  src: url(./fonts/SF-Pro-Display-Medium.otf) format('opentype')
}
@font-face {
  font-family: "SF";
  font-weight: 600;
  src: url(./fonts/SF-Pro-Display-Semibold.otf) format('opentype')
}
@font-face {
  font-family: "SF";
  font-weight: bold;
  src: url(./fonts/SF-Pro-Display-Bold.otf) format('opentype')
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section {
  margin-bottom: 20px;
}

.section > div {
  margin-bottom: 10px;
}

/* home page
custom styles
--------------------------------------------*/
.main-title{
  line-height: 3.4rem;
}
.bullet-info{
  border-radius: 0 1rem 1rem 0;
}
.step2 .bullet-info{
  border-radius: 1rem 0 0 1rem;
  width: 28rem;
}
.step1 img, .step2 img, .step3 img{
  border-radius: 1rem;
}
.step2 .images-container{
  width: 25rem;
}
.step3 .images-container{
  width: 23rem;
}
.step2 .images-container img:nth-child(2) {
  top: -2rem;
  right: 0; 
}
.step3 .images-container img:nth-child(2) {
  top: 2rem;
  right: 0rem;
}
.menu-button{
  top:1.3rem;
  right:1rem;
}
#dropdown-menu{
  z-index: 15;
  top: 4rem;
  right: 1rem;
}
#external-newsletter-form{
  height:16rem;
}
.video-responsive { 
  overflow:hidden; 
  padding-bottom:56.25%; 
  position:relative; 
  height:0;
  }
  
  .video-responsive iframe {
  left:0; 
  top:0; 
  height:100%;
  width:100%;
  position:absolute;
  }
@media (max-width: 1024px) {
  .bullet-info{
    border-radius: 1rem;
  }
  .step2 .bullet-info{
    border-radius: 1rem;
    width: auto;
  }
  .main-title{
    line-height: normal;
  }
}
@media (max-width: 768px) {
  .step2 .images-container,
  .step3 .images-container{
    width:17rem;
  }
  .step2 .images-container img:nth-child(2),
  .step3 .images-container img:nth-child(2)
   {
    top: 5rem;
    right: 0rem;
  }
}
/* end home page */

/* feedback */
.videostories-container{
  width:25rem;
}
@media screen and ( max-width: 590px )
{
  #external-newsletter-form{
    height:20rem;
  }
}
@media screen and ( max-width: 480px )
{
  .videostories-container{
    width:100%;
  }
}
/* end feedback */

/* postulate */
.offer-frame{
  border-radius: 1.5rem;
}
@media screen and ( max-width: 640px )
{
  .offer-frame{
    border-radius: 0;
  }
}

.other-video-container {
  float: left;
  width: 100%;
  height: 100%;
  background-color: black;
}

.loader{
  position: absolute;
  top: 50%;
  left:50%;
  margin-left:-20px;
}

.player-container {
  left:50%;
  transform:translateX(-50%)
}
.sub-player-container{
  width: 18rem;
  height: 25rem;
}
.video-js .vjs-poster{
  background-position:center 26%;
  background-color:transparent;
}
@media (hover: none) {
  a:hover { color: inherit; }
}
@media screen and ( max-height: 916px )
{

  .sub-player-container{
    width: 18rem;
    height: 17rem;
  }
}
@media screen and (max-height: 780px) {
  .sub-player-container {
    padding-top: 3rem;
  }
} 
@media (max-width: 767px) { 
  .player-container {
    left:auto;
    transform:none;
    margin-bottom: -4rem;
  }
  .sub-player-container{
    width: 18rem;
     height: 25.5rem;
  }
 }
/* end postulate */
/*custom styles
--------------------------------------------*/


/* Toggle A */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}